import { makeAutoObservable } from "mobx";
import { RDay, } from "../../api/orderingHoursWidget";
import { OrderingHoursBoundry } from "../../api/orderingHoursWidget/OrderingHoursBoundry";
import { ASAPFulfillmentTime, OnTimeFulfillmentTime } from "../FulfillmentTime";
export class NewFulfillmentTime {
    constructor(props) {
        this.orderingHoursWidget = props.orderingHoursWidget;
        this.scope = props.scope;
        this.fulfillmentInstructions = props.fulfillmentInstructions;
        makeAutoObservable(this);
    }
    get today() {
        return RDay.fromRDate(this.scope.now);
    }
    get value() {
        if (this.fulfillmentInstructions.fulfillmentTime === null) {
            return null;
        }
        if (this.fulfillmentInstructions.fulfillmentTime.type === "ASAP") {
            return { type: "ASAP" };
        }
        if (this.fulfillmentInstructions.fulfillmentTime.type === "OnTime") {
            return {
                type: "OnTime",
                date: this.fulfillmentInstructions.fulfillmentTime.date,
            };
        }
        throw new Error("Invalid fulfillment time");
    }
    get isDelivery() {
        return this.fulfillmentInstructions.isDelivery;
    }
    isProductAvailable(props) {
        if (this.value === null) {
            return true;
        }
        const valueDate = this.value.type === "ASAP" ? this.scope.now : this.value.date;
        return this.orderingHoursWidget.isAvailableAt({
            preorderSettings: props.preorderSettings,
            schedule: props.schedule,
            date: valueDate,
        });
    }
    setValue(value) {
        if (this.fulfillmentInstructions.type === "Online") {
            if (value.type === "ASAP") {
                this.fulfillmentInstructions.fulfillmentTime =
                    new ASAPFulfillmentTime();
                this.orderingHoursWidget.removeConflictsFromCart(this.scope.now);
                return;
            }
            else if (value.type === "OnTime") {
                this.fulfillmentInstructions.fulfillmentTime =
                    new OnTimeFulfillmentTime({ date: value.date });
                this.orderingHoursWidget.removeConflictsFromCart(value.date);
                return;
            }
        }
        throw new Error("Invalid fulfillment time");
    }
    getDates(preorderSettings, schedule, from, to) {
        if (from.isAfter(to)) {
            throw new Error(`Invalid date range ${from.toString()} to ${to.toString()}`);
        }
        const result = this.orderingHoursWidget.getDates({
            preorderSettings,
            schedule,
            from,
            to,
        });
        return result;
    }
    isOnlyAsap(preorderSettings, schedule) {
        const result = this.orderingHoursWidget.isOnlyAsap(preorderSettings, schedule);
        return result;
    }
    isAnyFutureDate(preorderSettings, schedule) {
        const result = this.orderingHoursWidget.isAnyFutureDate(preorderSettings, schedule);
        return result;
    }
    hasAnyAvailableTime(preorderSettings, schedule) {
        const result = this.orderingHoursWidget.hasAnyAvailableTime(preorderSettings, schedule);
        return result;
    }
    hasNext(after, preorderSettings) {
        var _a, _b;
        if (after === null) {
            return false;
        }
        return ((_b = (_a = new OrderingHoursBoundry({
            preorderSettings: preorderSettings,
            scope: this.scope,
        })
            .maxDay()) === null || _a === void 0 ? void 0 : _a.isAfter(after)) !== null && _b !== void 0 ? _b : false);
    }
    hasPrevious(before, preorderSettings) {
        var _a, _b;
        if (before === null || before.isBefore(this.today)) {
            return false;
        }
        return ((_b = (_a = new OrderingHoursBoundry({
            preorderSettings: preorderSettings,
            scope: this.scope,
        })
            .minDay()) === null || _a === void 0 ? void 0 : _a.isBefore(before)) !== null && _b !== void 0 ? _b : false);
    }
}
